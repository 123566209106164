import Carousel from "react-bootstrap/Carousel";
import Lottie from "lottie-react";
import header from "../img/headerlottie.json";

function Banner(props) {
  return (
    <Carousel
      buttonsVisibility="hide"
      controls={false}
      className="banner"
      onClick={props.toggleall}
      onMouseEnter={props.toggleall}
    >
      <Carousel.Item>
        <Lottie className="d-block w-100" animationData={header} loop={true} />
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;
