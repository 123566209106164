import React from "react";
//import {Link} from 'react-router-dom';
//import card2 from '../img/Airtime.svg'

function Securitycard(props) {
  return (
    <div
      className="Aboutcard"
      onClick={props.toggleall}
      onMouseEnter={props.toggleall}
    >
      <div className="about-container bg">
        <div className="about-text">
          <h2>We Care About Your Data</h2>
          {/* <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
            </div> */}

          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla
            vestibulum vel nulla a consequat. Donec facilisis, leo{" "}
          </span>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            vestibulum vel nulla a consequat. Donec facilisis, leo{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Securitycard;
